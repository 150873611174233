import { Address, CustomerAddress } from '@commercelayer/sdk';
import t, { Labels, tt } from '@lib/locales';
import { Locales } from '@model/common';
import { Level } from '@model/product';
import { AddressFormData, BillingAddressFormData } from '@src/types/address';
import { getCountries } from '@utils/countryStateCity';
import _ from 'lodash';
import markets from '../../../data/markets.json';
import { parseUrl } from './pathUtils';

export const formatAddress = (payload: AddressFormData | BillingAddressFormData): Address => {
  const address: Address = {
    id: payload.id || '',
    first_name: payload.first_name || '',
    company: payload.company || '',
    last_name: payload.last_name || '',
    line_1: payload.line_1 || '',
    line_2: payload.line_2 || '',
    city: payload.city || '',
    country_code: payload.country_code || '',
    state_code: payload.state_code || '',
    zip_code: payload.zip_code || '',
    phone: payload.phone || '',
    type: 'addresses',
    created_at: null,
    updated_at: null,
    metadata: { ...payload },
  };

  return address;
};

export const hasCountryAddress = (addresses: CustomerAddress[], countryCode: string) => {
  if (!addresses || addresses.length == 0) {
    return false;
  }
  const filteredAddresses = addresses.filter(
    (a) => a.address.metadata.type != 'test' && a.address.country_code === countryCode
  );
  return filteredAddresses.length > 0;
};

export const hasBothAddresses = (addresses: CustomerAddress[]) => {
  if (!addresses || addresses.length == 0) {
    return false;
  }

  let hasBilling = false;
  let hasShipping = false;

  for (let address of addresses) {
    if (address.address.metadata.isBilling) {
      hasBilling = true;
    }
    if (address.address.metadata.isShipping) {
      hasShipping = true;
    }

    if (hasBilling && hasShipping) {
      return true;
    }
  }

  return false;
};

export const getCountryName = (locale: Locales, cc: string) => {
  if (!cc) {
    return;
  }
  return t(locale, `countries.${cc}` as Labels);
};

export const filterCountries = (promotedCountry: string) => {
  const countrycodes = markets.reduce((all, m) => {
    const { zone_code } = m;
    all = [...all, ...zone_code.split('|')];
    return _.uniq(all);
  }, []);
  const selectedCountries = getCountries().filter((c) => countrycodes.includes(c.value));
  if (!promotedCountry) {
    return selectedCountries;
  }

  const toPromote = selectedCountries.find((c) => c.value === promotedCountry.toUpperCase());
  const rest = selectedCountries.filter((c) => c.value !== promotedCountry.toUpperCase());
  return [toPromote, ...rest];
};

export const getMarketWithCurrency = () => {
  const group = _.groupBy(markets, 'zone_name');
  const withCurrency = _.keys(group)
    .map((k) => {
      return { zone: k, values: group[k] };
    })
    .filter((c) => c.values.length > 1);

  return withCurrency;
};

export const findMarketByUrlParams = (zone: string, currency: string) => {
  let m = markets.find(
    (m) => m.currency === currency.toUpperCase() && m.zone_code.indexOf(zone.toUpperCase()) > -1
  );
  if (!m) {
    const name = `${zone} ${currency}`.toUpperCase();
    m = markets.find((m) => m.market_name === name);
  }
  return m;
};

export const getMarketByCountryCode = (countryCode: string) => {
  return markets.find((m) => m.zone_code.indexOf(countryCode.toUpperCase()) > -1);
};

export const filterMarketByCountryCode = (countryCode: string) => {
  return markets.filter((m) => m.zone_code.indexOf(countryCode.toUpperCase()) > -1);
};

const splitPath = (str: string, locale = 'it') => {
  const parts = str.split(' ');
  const currency = parts[parts.length - 1];
  parts.pop();
  const prefix = parts.join('-');
  return `/${prefix}/${currency}/${locale}`.toLowerCase();
};

export const getPathFromMarket = (marketNumber: number, locale = 'it') => {
  const countryInfo = markets.find((c) => {
    const { market_number } = c;
    if (market_number.toString() === marketNumber.toString()) {
      return true;
    }
    return false;
  });
  if (countryInfo) {
    const { market_name } = countryInfo;
    const path = splitPath(market_name, locale);
    return path;
  } else {
    return null;
  }
};

export const getMarketFromUrl = (url: string) => {
  try {
    const parsedUrl = parseUrl(url);
    const { country, currency } = parsedUrl;
    const urlMarket = findMarketByUrlParams(country, currency);
    const { market_number: marketNumber } = urlMarket;
    let countryCode = country.toUpperCase();
    const allCountries = urlMarket.zone_code.split('|');
    if (!allCountries.includes(country.toUpperCase())) {
      countryCode = allCountries[0];
    }
    return { marketNumber, countryCode };
  } catch (error) {
    console.error('getMarketFromUrl', error);
  }
};

export const getMaxDeliveryDays = (levels: Level[], qt = 1) => {
  const levelsWithAvailability = levels.filter(function (level) {
    return level.quantity >= qt;
  });

  const level = levelsWithAvailability[0] ?? null;

  if (level) {
    const minDay = (level.delivery_lead_times || []).reduce((max, dlt) => {
      // return the level with highest shipping times
      return max > dlt.min.days ? max : dlt.min.days;
    }, 0);

    const maxDay = (level.delivery_lead_times || []).reduce((max, dlt) => {
      return max > dlt.max.days ? max : dlt.max.days;
    }, 0);

    return { minDay, maxDay };
  }
  return { minDay: 7, maxDay: 14 };
};

export const getDateRange = (locale: Locales, from: number, to: number) => {
  const d1 = new Date();
  d1.setDate(d1.getDate() + from);
  const d2 = new Date();
  d2.setDate(d2.getDate() + to);
  const dl1 = new Intl.DateTimeFormat([locale, 'en']).format(d1);
  const dl2 = new Intl.DateTimeFormat([locale, 'en']).format(d2);

  return tt(locale, 'delivery_dates', {
    min: dl1,
    max: dl2,
  });
};

function findK(object: any, key: any, values: any = []) {
  Object.keys(object).some((k) => {
    if (object[k] && typeof object[k] === 'object') {
      findK(object[k], key, values);
    } else if (k === key) {
      values.push(object[k]);
    }
  });
  return values;
}

export const handleCommerceLayerError = (errorObj: any) => {
  const messages = findK(errorObj, 'message', []);
  const codes = findK(errorObj, 'code', []);
  return `${messages.join(',')}
  Error type: CommerceLayer.
  Error codes: (${_.uniq(codes).join(', ')})`;
};

export const handleBraintreeError = (errorObj: any) => {
  const messages = findK(errorObj, 'message', []);
  const codes = findK(errorObj, 'code', []);
  let stringMessage = `${messages.join(',')}.
    Error type: ${errorObj.name} - ${errorObj.type}.
    Error codes: (${codes.join(', ')})`;
  return stringMessage;
};

export const getFormattedAvailabilityText = (
  levels: Level[],
  locale: Locales,
  DAYS_LIMIT = 2,
  QT_TRESHOLD = 10,
  qt = 1
) => {
  const levelsWithAvailability = levels.filter(function (level) {
    return level.quantity >= qt;
  });

  const level = levelsWithAvailability[0] ?? null;
  if (level) {
    const maxDay = (level.delivery_lead_times || []).reduce((max, dlt) => {
      return max > dlt.max.days ? max : dlt.max.days;
    }, 0);

    if (maxDay >= DAYS_LIMIT) {
      if (level.quantity > QT_TRESHOLD)
        return tt(locale, 'delivery_times', { days: maxDay.toString() });
      else
        return `${tt(locale, 'delivery_times', { days: maxDay.toString() })} ${tt(
          locale,
          'until_items',
          {
            num: level.quantity.toString(),
          }
        )} ${t(locale, level.quantity == 1 ? 'single_product_basepath' : 'product_basepath')}`;
    }
    return t(locale, 'ready_in_stock');
  }
  return null;
};
